import React from "react";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import InfoBox from "../../components/InfoBox";
import QuoteBox from "../../components/QuoteBox";
import CtaPrimary from "../../components/CtaPrimary";
import { currentYear } from "../../components/Helpers";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import ImgContainerFixed from "../../components/ImgContainerFixed";
import H from "../../components/Headline";


const breadCrumbLevels = {
  Hem: "/",
  "Analys Konsultation": "/se/analysrådgivning",
  "GTM Tutorial": "/se/google-tag-manager-installation"
};

// hreflang data
const alternateLangs = [
  {
    hreflang: "se",
    href: "/se/google-tag-manager-installation"
  },
  {
    hreflang: "da",
    href: "/da/google-tag-manager-opsætning"
  },
  {
    hreflang: "no",
    href: "/no/google-tag-manager-oppsett"
  },
  {
    hreflang: "nl",
    href: "/nl/google-tag-manager-setup"
  },
  {
    hreflang: "en",
    href: "/en/google-tag-manager-setup"
  },
  {
    hreflang: "de",
    href: "/de/google-tag-manager-einrichten"
  },
  {
    hreflang: "x-default",
    href: "/en/google-tag-manager-setup"
  }
];

const FAQs = `
{
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Behöver jag Google Tag Manager?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Ja, eftersom din webbplats förmodligen vill köra Google Analytics och andra tredjeparts skript-taggar. Att ställa in allt detta är mycket enklare och snabbare med Google Tag Manager. Dessutom <a href='https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173'>laddar din webbplats lite snabbare</a> också."
        }
      },
      {
      "@type": "Question",
      "name": "När ska jag använda Google Tag Manager?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Redan om du bara vill köra Google Analytics bör du använda Google Tag Manager. Att ställa in <a href='https://bluerivermountains.com/se/händelsespårning'>händelsespårning</a> och andra konfigurationer är mycket snabbare och enklare med GTM. Dessutom finns det många handledningar och guider online som förklarar hur du till exempel <a href='http://bluerivermountains.com/se/google-analytics-setup'>ställer in Google Analytics</a>."
        }
      },
      {
        "@type": "Question",
        "name": "Hur använder jag Google Tag Manager med Google Analytics?",
        "acceptedAnswer": {
        "@type": "Answer",
        "text": "Sätt inte in Google Analytics-taggen i källkoden på din webbplats. Lägg bara till Google Tag Manager-taggen och implementera och <a href='https://bluerivermountains.com/se/google-analytics-setup'>ställ in Google Analytics</a> genom Google Tag Manager. Alla anpassade konfigurationer som <a href='https://bluerivermountains.com/se/händelsespårning'>händelsespårning</a> eller tillägg av andra skript-taggar görs i GTM."
        }
     },
     {
       "@type": "Question",
       "name": "Vad är skillnaden mellan Google Analytics och Google Tag Manager?",
       "acceptedAnswer": {
       "@type": "Answer",
       "text": "Google Analytics är biblioteket som samlar in data om besök på din webbplats. Google Tag Manager å andra sidan är ett bibliotek som körs på din webbplats för att implementera andra bibliotek eller verktyg som Google Analytics. Eftersom många av dessa verktyg har extra JavaScript-snippets för att skicka data till dem, ställer du in dem alla i GTM."
       }
    },
    {
      "@type": "Question",
      "name": "Var ska jag placera Google Tag Manager-koden?",
      "acceptedAnswer": {
      "@type": "Answer",
      "text": "Den första delen av koden går så högt som möjligt i <head>-sektionen. Jag rekommenderar att implementera den inom <head> men efter alla <style> eller <script>-taggar som är viktiga för att rendera sidan - eftersom vi inte vill fördröja dem. Den andra delen av GTM-kodsnutten är för att aktivera grundläggande funktionalitet på webbplatser med JavaScript avstängt. Den går så högt som möjligt i <body>-elementet. Logiken bakom positioneringen av båda taggarna är att säkerställa tidig laddning av GTM. Det gör att du kan påverka och kontrollera delar av sidladdningsprocessen så tidigt som möjligt."
      }
   },
   {
     "@type": "Question",
     "name": "Inkluderar Google Tag Manager Google Analytics?",
     "acceptedAnswer": {
     "@type": "Answer",
     "text": "Nej, men Google Tag Manager gör att du kan implementera Google Analytics på några sekunder med bara några få klick. Det enda du behöver är ditt Google Analytics-spårnings-ID. Generellt sett behöver du dock inte använda Google Analytics med Google Tag Manager. De är oberoende av varandra."
     }
  }
  ]
}
`;

const GTMsetup = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title={`Installera Google Tag Manager Tutorial: Installationsguide ${currentYear}`}
        description="Lär dig hur du använder GTM för att ställa in Google Analytics, händelsespårning, remarketing-taggar och ett datalager, samt bästa praxis för att spåra nedladdningar och externa länkar."
        lang="se"
        canonical="/se/google-tag-manager-installation"
        image="google-tag-manager-tutorial-hero.png"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <ImgScreenshot
          src="gtm-setup/google-tag-manager-tutorial-hero.png"
          alt="användargränssnittet för Google Tag Manager"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Tag Manager Tutorial</H>

        <p>Som en <Link to="/se/google-tag-manager-konsult">Google Tag Manager-konsult</Link> har jag installerat GTM på <b>100+ kundwebbplatser</b>. Denna Google Tag Manager-tutorial är där jag lär dig processen jag har förfinat genom åren, steg för steg, med exempel och videor för dig att lära dig.</p>
        <p>Längre ner kan du <Link to="/se/google-tag-manager-installation#download-gtm-config-container-file">ladda ner en GTM-konfigurationsfil</Link> med alla följande bästa praxis för att importera till din container.</p>
        <p>Om du inte kan vänta, hoppa direkt till <Link to="/se/google-tag-manager-installation#install-google-tag-manager-on-your-website">installations</Link> tutorialen eller lär dig <Link to="/se/google-tag-manager-installation#how-to-set-up-google-tag-manager">hur du ställer in Google Tag Manager</Link>. Men om du är en <b>nybörjare</b> är det viktigt att först förstå <em>hur</em> man använder ett <Link to="/se/tagghantering">tagghanteringssystem</Link> tillsammans med andra verktyg.</p>
        <p>Så fortsätt läsa nedan först.</p>
        <H as="h2">Hur använder man Google Tag Manager?</H>
        <p>Jag antar att du redan vet <Link to="/se/vad-ar-google-tag-manager">vad Google Tag Manager är</Link>. Så låt oss prata om hur GTM fungerar och hur man använder det.</p>
        <p>Idealiskt sett vill du bara ha <b>en</b> 3:e parts tagg i källkoden på din webbplats eller web app.</p>
        <QuoteBox
          quote={`Den enda 3:e parts taggen på din webbplats bör vara Google Tag Manager-kodsnutten.`}
        />
        <p>Alla andra taggar implementeras sedan genom tagghanteraren själv. Andra marknadsförings- och spårningstaggar är t.ex. Google Analytics (GA), Facebook, Twitter, Linkedin, AdWords, DoubleClick och gud vet vad.</p>
        <p>Den främsta anledningen är <Link to="/se/fordelar-med-google-tag-manager">fördelarna med Google Tag Manager</Link>:</p>
        <ul>
          <li><b>enklare & snabbare</b> implementering av marknadsföringstaggar</li>
          <li>skalbarhet på varje sida och över flera domäner</li>
          <li><b>inbyggda triggers</b> för formulärinlämningar, scrollspårning & videospårning</li>
          <li>hantera användare med flera gtm-konton</li>
          <li>lite <a rel="noopener" target="_blank" href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">snabbare sidladdningshastighet</a></li>
        </ul><br />
        <p>På grund av dessa fördelar använder redan <a target="_blank" href="https://w3techs.com/technologies/overview/tag_manager">30% av alla webbplatser på internet en tagghanterare</a>. Och bland dem har Google Tag Manager en marknadsandel på <a target="_blank" rel="noopener" href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet">94%</a>.</p>
        <p>Så, om du inte har en solid anledning att inte lägga till en tagg till GTM, som en allmän tumregel, <b>lägg till alla taggar i GTM
          containern</b>.</p>

        <QuoteBox
          quote={`Använd GTM som ett anslutningslager mellan din webbplats och 3:e parts taggar.`}
        />
        <p>Använd GTM som ett <b>mellanlager</b> mellan din webbplats och 3:e parts tjänster. Utan det är din webbplats och 3:e parts taggar inte i direkt anslutning. Dessa tjänster är mestadels JavaScript-bibliotek för marknadsförings- eller spårningsverktyg som implementeras med en tagg. Men alla andra taggar gäller också.</p>
        <p>Det enda undantaget från regeln gäller när du gör konverteringsoptimering med split-testing verktyg.</p>
        <p>För under konverteringsoptimering, A/B-tester kommer att ladda olika varianter av en sida. Så besökaren kan se hur innehållet återigen renderas för en bråkdel av en sekund.</p>
        <p>För att undvika CSS-flimmer och säkerställa att varianttester laddar snabbt, kan en split-testing tagg också gå direkt in i källkoden.</p>
        <p>Nu när vi har detta ur vägen, låt oss titta på implementeringen.</p>
        <H as="h2">Installera Google Tag Manager på din webbplats</H>
        <p>Låt oss börja Google Tag Manager-tutorialen genom att visa dig var du får Google Tag Manager-kodsnutten och sedan var du installerar den på webbplatsen. Du kan logga in bara genom att använda ditt vanliga Google-konto.</p>
        <ol>
          <li><H as="h3">Skapa ett Google Tag Manager-konto</H>
            För att installera GTM, måste du först gå till <a rel="noopener" target="_blank"
              href="https://tagmanager.google.com/">officiella webbplatsen</a> och skapa ett nytt Google Tag Manager-konto.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-create-account.png"
              alt="Skapa ett Google Tag Manager-konto"
              caption="Först, skapa ett Google Tag Manager-konto och välj ett container-namn, som din webbplats namn och få sedan kodsnutten."
              className="article-img"
            />
          </li>
          <li><H as="h3">Skapa en webbegendom</H>
            Välj egendomen <b>Webb</b> för att få en kod för en webbplats eller web app.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-analytics-setup-via-google-tag-manager-container-creation.png"
              alt="GTM container-namn och webb-egendomsval"
              className="article-img"
              caption="Det finns flera typer av containers tillgängliga i ett GTM-konto. För webbplatser, välj webb. Observera att det finns andra tagghanterarcontainertyper för AMP-sidor, iOS och Android också."
            />
          </li>
          <li><H as="h3">Implementera Google Tag Manager-koden</H><ul>
            Efteråt kommer du att visas Google Tag Manager-koden att implementera på din webbplats.<br />
            <ImgScreenshot
              src="google-analytics-setup/google-tag-manager-code-snippet.png"
              alt="Google Tag Manager kodsnutt"
              caption="Detta är Google Tag Manager container-taggen. Den har två delar. Instruktionerna om hur man implementerar skripttaggarna är skrivna ovanför varje del."
              className="article-img"
            />
            <li>Ta den <b>första delen</b> av <Link to="/se/wiki-analytics/behållar-tag">container-taggen</Link> och placera den så högt som möjligt i <b>head</b> taggen på varje sida på din webbplats. Detta säkerställer att du kan köra taggar tidigt under sidladdningar.</li>
            <li>Den <b>andra delen</b> är en iframe för att köra i webbläsare som har JavaScript inaktiverat. Installera taggen så högt som möjligt i <b>body</b> taggen på varje sida på din webbplats.<br />
              <ImgScreenshot
                src="data-layer/positionierung-data-layer-gtm.png"
                alt="dataLayer är placerad före TMS taggen i källkoden"
                caption={`Den första taggen i <head> är ett datalager. Oroa dig inte om du ännu inte vet vad det är (första pilen). Nästa är den första delen av GTM-taggen (andra pilen). Slutligen implementeras den andra delen av GTM-taggen högt upp i <body> elementet.  JavaScript-kod kan implementeras emellan, men ett datalager implementeras alltid före GTM-taggen och <noscript> GTM-taggen är alltid sist.`}
                className="article-img"
              />
            </li>
          </ul>
          </li>

        </ol>
        <p>Detta är den vanliga metoden för att implementera GTM.</p>
        <p>Använder du ett populärt innehållshanteringssystem? Om ja, kan du också använda ett <b>plugin</b> som tar hand om Google Tag Manager-installationen.</p>
        <p>Med det sagt:</p>
        <InfoBox
          type="info"
          headline="Om ditt CMS också erbjuder dig ett plugin för att installera andra taggar"
          h="false"
          dots="true"
          tweetcopy="Om ditt CMS också erbjuder dig ett plugin för att installera andra taggar, använd inte ännu ett plugin för att installera Google Analytics, Facebook eller Google Ads. Använd istället GTM för att installera dessa taggar."
        >
          <p>Använd inte ännu ett plugin för att installera Google Analytics, Facebook eller Google Ads.</p>
          <p>Använd istället <b>GTM för att installera dessa taggar</b>.</p><br />
          <ol>
            <li>Det kommer att resultera i snabbare sidladdningshastighet</li>
            <li>Det ger dig fler alternativ för att konfigurera taggen</li>
          </ol>
        </InfoBox>
        <p>GTM-användargränssnittet får också regelbundna uppdateringar med nya funktioner, så du är nästan alltid bättre av att implementera andra marknadsföringstaggar direkt med det än med en annan integration.</p><p>Plus, vinsterna i laddningstid är bra för din <Link to="/se/wiki-analytics/avvisningsfrekvens">avvisningsfrekvens</Link> och hjälper SEO.</p>
        <H as="h3">Använd ett plugin för att implementera GTM</H>
        <p>Nedan är en lista över de vanligaste innehållshanteringssystemen och deras plugins för att installera Google Tag Manager.</p>
        <H as="h4">WordPress</H>
        <p>Det finns två WordPress-plugins för att implementera GTM som jag skulle använda. <b>Först</b>, finns det det klassiska alternativet som kallas <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/duracelltomi-google-tag-manager/">Google Tag Manager
          för WordPress</a>.<br />
          <b>Det andra</b> alternativet är <a rel="noopener" target="_blank" href="https://wordpress.org/plugins/google-site-kit/">Site
            Kit av Google</a>. Det tillåter främst att du lägger till en instrumentpanel till din Wordpress-backend som visar information från ditt Google Analytics-konto och Google Search Console-data - vilket är ganska trevligt. Och det låter dig också installera GTM.</p>
        <H as="h4">Shopify</H>
        <p>För Shopify finns det ett <b>gratis</b> plugin för GTM-installation kreativt kallat <em><a rel="noopener" target="_blank"
          href="https://apps.shopify.com/trafficguard?surface_detail=google+tag+manager&amp;surface_inter_position=1&amp;surface_intra_position=6&amp;surface_type=search">Google
          Tag Manager Installer</a></em>.</p>
        <H as="h4">Squarespace</H>
        <p>För Squarespace finns det ingen GTM-extension eller plugin. Men du kan lägga till GTM-taggen manuellt, genom att gå till <b>sidebar</b> &gt; <b>settings</b> &gt; <b>advanced</b> &gt; <b>code injection</b>.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-installation-in-squarespace.png"
          alt="Navigationsmenyn i Squarespace för kodinjektion"
          caption={`I Squarespace kan du implementera GTM under Inställningar > Avancerat > Kodinjektion`}
          className="article-img"
        />

        <p>Nästa steg är att klistra in GTM-taggen i formulärfälten så här:</p>

        <ImgScreenshot
          src="gtm-setup/gtm-code-injection-in-squarespace.png"
          alt="Injicera GTM-kodsnuttar i Squarespace"
          caption={`Placera den första delen av GTM-koden i header-fältet. Den andra delen går i footer-fältet.`}
          className="article-img"
        />

        <H as="h4">Wix</H>
        <p>Besök huvudmenyn för din Wix-webbplats i vänstra sidofältet. Därifrån besöker du <b>Marknadsföring &amp; SEO</b> och klickar sedan på <b>Marknadsföringsintegrationer</b> längre ner i sidofältet.<br />
          Där hittar du flera Wix-integrationer för Google Analytics, Facebook-pixeln och även en Wix-extension för att implementera Google Tag Manager.</p>

        <ImgScreenshot
          src="gtm-setup/google-tag-manager-setup-in-wix.png"
          alt="Wix marknadsföringsintegration för Google Tag Manager"
          caption={`I Wix använder du marknadsföringsintegrationen för Google Tag Manager.`}
          className="article-img"
        />
        <p>Klicka på anslut och installera Google Tag Manager.</p>

        <H as="h2">Hur kontrollerar man om GTM fungerar?</H>
        <InfoBox
          type="info"
          headline="När du först loggar in på GTM"
          h="false"
          dots="true"
          tweetcopy="När du först loggar in på GTM...Gå till skicka-knappen och publicera en tom container. Annars, när du testar om GTM fungerar, kommer skriptet att returnera ett 400-svarsfel och du kommer att spendera timmar på att felsöka varför. 😭"
        >
          <p>Gå till skicka-knappen och publicera en <b>tom container</b>.</p>
          <p>Annars, när du testar om GTM fungerar, kommer skriptet att returnera ett <b>400-svarsfel</b> och du kommer att spendera timmar på att felsöka varför. 😭 <br /><br />Det är en klassiker 😉</p>
        </InfoBox>
        <p>Efter att du implementerat GTM-skriptet och <b>publicerat en container</b> version (viktigt), kan du testa om Google Tag Manager fungerar genom att göra något av dessa kontroller:</p>
        <ol>
          <li><H as="h3">Förhandsgranskning och felsökningsläge</H>Logga in på ditt GTM-konto och klicka på <b>förhandsgranska</b>. Öppna sedan en ny flik i webbläsaren och besök din webbplats. GTM-felsökningsfönstret ska dyka upp längst ner i fönstret om GTM fungerar korrekt.<br />
            <ImgScreenshot
              src="event-tracking/google-tag-manager-event-trigger-preview.png"
              alt="Aktivera GTM felsökningsläge"
              caption={`Aktivera GTM felsökningsläge för att kontrollera om GTM fungerar korrekt.`}
              className="article-img"
            />

          </li>
          <li><H as="h3">Chrome Developer Tools</H><b>Öppna Chrome Developer Tools</b> med en högerklick på vilken sida som helst på din webbplats och välj <em>inspektera</em> (alternativt F12 på Windows och Shift+CTRL+J på Mac).<br />
            Gå sedan till fliken <b>nätverk</b> och <b>ladda om webbsidan samtidigt</b> (F5 på Windows och CMD+Shift+R på Mac). Nätverksfliken fylls med alla nätverksförfrågningar som behövs för att ladda sidan.<br />
            I filterfältet uppe till vänster, skriv <em>gtm.js</em> för att hitta förfrågan för din JavaScript-kod och verifiera att den har en <b>statuskod på 200</b>.<br /><br />
            Låt mig visa dig:<br /><br />
            <video
              loading="lazy"
              title={`Kontrollera om Google Tag Manager fungerar`}
              autoplay
              muted
              playsinline
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/check-if-gtm-is-working.mp4" type="video/mp4" />
            </video>
            <br />
            <b>Om du inte har en 200 statuskod, kanske du glömde att skicka och publicera en container först i GTM?</b></li>
          <li><H as="h3">Google Tag Assistant</H>Installera <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/tag-assistant-by-google/kejbdjndbnbjgmefkgdddjlbokphdefk">Google Tag Assistant</a> Chrome-tillägget och starta det på din webbplats. Det bör visa ett GTM-container-ID.<br />

          <ImgContainerFixed width="452px">
              <ImgScreenshot
                src="gtm-setup/gtm-validation-with-tag-inspector.png"
                alt="Google Tag inspector validerar att GTM laddas korrekt"
                caption={`Du kan också använda Chrome-tillägget Google Tag Assistant för att säkerställa att Google Tag Manager fungerar korrekt.`}
                className="article-img"
              />
            </ImgContainerFixed>
          </li>
        </ol>
        <H as="h2">Hur ställer man in Google Tag Manager?</H>
        <p>När du ställer in Google Tag Manager kan du göra många avancerade konfigurationer. Så <b><em>hur</em></b> du ställer in GTM beror på vilka andra verktyg du planerar att använda i ditt <Link to="/se/tagghantering">tagghanteringssystem</Link>.</p>
        <p>Det är därför jag har samlat alla relevanta handledningar som täcker vad du än kan tänkas vilja ställa in i ditt GTM-konto - med exempel. Följ bara denna Google Tag Manager-guide och skapa därigenom en solid grund för tagghantering på din webbplats.</p>
        <p>Endast handledningen om att implementera ett datalager kräver kodningskunskaper eller potentiellt webbutvecklare.</p>
        <p><b>Obs</b>: I denna Google Tag Manager-handledning kommer vi att använda GTM genom att <b>manuellt</b> ställa in nya taggar och triggers för varje händelse. Metoden är inte super skalbar, men den är tillräckligt snabb och enkel, samtidigt som den uppfyller de flesta spårningsambitioner och ändå är tillämplig på andra avancerade inställningar.</p>
        <p>Större webbplatser och e-handelsbutiker kräver en <b>skalbar lösning för tagghantering</b>. Därför implementeras ett <Link to="/se/data-lager">datalager</Link> som den centrala delen för att spåra händelser. Med en sådan inställning kan du använda händelsehanterare istället för att ställa in taggar, triggers och variabler för varje händelse.</p>
        <ol>
          <li>
            <H as="h3">Ställ in Google Analytics-spårning</H>
            <p>Detta är det första steget för alla. Lär dig i denna guide hur man implementerar solid Google Analytics-spårning, med mål, trattar och dina egna besök
              exkluderade från trafiken. Plus fler bästa praxis.</p>

            <Link to="/se/google-analytics-setup"><CtaPrimary color="purple" arrow="true">Ställ in Google Analytics</CtaPrimary></Link>

          </li>
          <li>
            <H as="h3">Ställ in händelsespårning</H>
            <p>När den grundläggande spårningsimplementeringen fungerar som den ska, vill du också lära dig att spåra användarengagemang. Hur ofta, till exempel, skickar en besökare formulärinlämningar och klickar på en skicka-knapp eller ett annat HTML-element? Min <Link to="/se/händelsespårning">händelsespårning</Link> guide förklarar exakt det för ett <b>knappklick</b> och
              du kan använda samma metod för all annan klickspårning.</p>
            <Link to="/se/händelsespårning"><CtaPrimary color="purple" arrow="true">Ställ in händelsespårning</CtaPrimary></Link>
          </li>
          <li>
            <H as="h3">Lägg till remarketing-taggar</H>
            <p>Det vanligaste användningsfallet för GTM <em>efter</em> att ha installerat GA är att lägga till remarketing-taggar på en webbplats. När allt kommer omkring, utgör de majoriteten av tredjeparts marknadsföringstaggar och spårningskoder som rör till vår kodbas.<br />
              Därför implementerar vi dem genom vårt GTM-konto för att hålla kodbasen ren från marknadsförings- och analytikstaggar samtidigt som vi drar nytta av <Link to="/se/fordelar-med-google-tag-manager">fördelarna med Google Tag Manager</Link>.</p>
            <p>Låt oss lära oss hur man lägger till de vanligaste remarketing-taggarna inom digital marknadsföring, <b>Facebook-pixeln</b>, och <b>Google Ads remarketing-taggen</b>.</p>
            <H as="h4">Lägg till Facebook-pixel</H>
            <ul>
              <li>
                Först behöver du ditt <b>Facebook-pixel-ID</b>. Besök Facebooks <a rel="noopener" target="_blank"
                  href="https://www.facebook.com/events_manager2/list/pixel/">Händelsehanterare</a> och klicka på den <b>gröna plussymbolen</b> för att skapa Facebook-pixeln. Därefter kommer ditt pixel-ID att visas på skärmen.
                <ImgScreenshot
                  src="gtm-setup/get-fb-pixel.png"
                  alt="Händelsehanteraren i Facebook visar pixel-ID:t"
                  caption={`Hitta ditt Facebook-pixel-ID i händelsehanteraren.`}
                  className="article-img"
                />
              </li>
              <li>
                Skapa sedan via Google Tag Manager en <b>ny tagg</b>, kalla den till exempel <em>Facebook - Sidvisning</em> och besök galleriet för <b>taggmallar</b>.
              </li>
              <li>
                Sök efter <em>Facebook</em> och välj Facebook Pixel.<br />

                <ImgScreenshot
                  src="gtm-setup/fb-pixel-template-gallery-gtm.png"
                  alt="GTM:s mallgalleri visar Facebook-pixeltagg"
                  caption={`Implementera Facebook-pixeln från GTM:s taggmallar.`}
                  className="article-img"
                />
              </li>
              <li>
                Lägg till ditt <b>Facebook-pixel-ID</b> och klicka på <b>spara</b>.
              </li>
              <li>
                Ställ in taggen att utlösas på <b>alla sidor</b>.
                <br /><br />
                <video
                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Installera Facebook-pixeln i Google Tag Manager`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/add-facebook-pixel-with-gtm.mp4" type="video/mp4" />
                </video>
              </li>
              <li>
                Klicka sedan på <b>skicka</b> i det övre högra hörnet för att göra taggen live.
              </li>
            </ul>
            <H as="h4">Lägg till Google Ads remarketing</H>
            <ul>
              <li><b>Först</b>, få ditt <b>Google Ads-konverterings-ID</b> för din målgrupp från <b>Delat bibliotek &gt; Målgrupper</b>. Användargränssnittet ändrades nyligen, men leta efter <b>taggdetaljer</b> eller <b>ställ in tagg</b> för att hitta nedanstående information.
                <br />
                <ImgContainerFixed width="432px">
                  <ImgScreenshot
                    src="gtm-setup/google-ads-conversion-id.png"
                    alt="Kodsnuttar för Google-konverterings-ID och konverteringsetikett"
                    caption={`Ta ditt konverterings-ID och konverteringsetikett från taggdetaljerna i din målgrupp.`}
                    className="article-img"
                  />
                </ImgContainerFixed>
              </li>
              <li>Gå sedan i GTM till sektionen <b>taggar</b> och klicka på <b>ny</b> för att lägga till vår nya marknadsföringstagg.</li>
              <li>Ge den ett namn som <em>Google Ads - Sidvisning</em>.</li>
              <li>Välj som typ av variabel <b>Google Ads Remarketing</b>.</li>
              <li>Ange ditt <b>konverterings-ID</b> och eventuellt <b>konverteringsetiketten</b>. Låt sedan taggen utlösas på <b>alla sidor</b> och klicka på <b>spara</b>.<br /><br />Låt mig visa dig i denna video:
                <video
                  loading="lazy"
                  autoplay
                  muted
                  playsinline
                  loop
                  controls
                  title={`Installera Google Ads sidvisning med GTM`}
                  css="max-width:100%;border:3px solid lightgrey;"
                >
                  <source src="/video/google-ads-page-view.mp4" type="video/mp4" />
                </video>
              </li>
              <li>Klicka på <b>skicka</b> i det övre högra hörnet för att göra marknadsföringstaggen live.</li>
            </ul>
          </li>
          <li>
            <H as="h3">Implementera ett datalager</H>
            <p>Du kommer vilja implementera ett datalager om du ställer in taggar regelbundet och det tar för lång tid och är helt enkelt för arbetskrävande.</p>
            <p>En annan fördel är att du kan använda informationen från din databas för att utlösa triggers eller skicka det som händelsedata. Andra externa datakällor kan också integreras. Webbplatser som behöver e-handels-spårning faller typiskt in i denna kategori.</p>
            <p>Min artikel om datalagret förklarar implementeringen, datalager-variabler och hur man konfigurerar anpassad spårning på ett skalbart sätt, vilket är ett typiskt användningsfall för <b>stora e-handelsbutiker</b> som behöver avancerad e-handels-spårning.
            </p>

            <Link to="/se/data-lager"><CtaPrimary color="purple" arrow="true">Implementera datalager</CtaPrimary></Link>
          </li>
        </ol>
        <H as="h2">Bästa praxis för varje GTM-installation</H>
        <p>Varje gång jag ställer in Google Tag Manager kommer installationen med några konfigurationer som jag lägger till varje gång. Dessa bästa praxis är tillämpliga och hjälpsamma för de flesta företag och bör inte saknas i någon GTM-handledning. Se listan nedan och välj de som är användbara för dig.</p>
        <p>Längre ner kan du <Link to="/se/google-tag-manager-installation#download-gtm-config-container-file">ladda ner en GTM-konfigurationsfil</Link> med alla dessa bästa praxis för att importera till din egen container.</p>
        <H as="h3">Spåra utgående länkar</H>
        <p>Att spåra klick på utgående länkar innebär att spåra alla klick på externa länkar som leder från din webbplats till andra webbplatser. Spårning av externa länkar är en bästa praxis som låter dig veta till vilka webbplatser du skickar besök och hjälper dig att verifiera intresset hos dina besökare.<br />För att implementera spårning av externa länkar finns det <b>tre steg</b>:</p>
        <ol>
          <li>
            <H as="h4">Skapa en anpassad händelsevariabel i GTM</H>
            <ul>
              <li>Besök <b>användardefinierade variabler</b> från GTM-översikten och klicka på <b>ny &gt; auto-event variabel</b>.</li>
              <li>Den ska utvärdera länk-URL:n för ett klickat element och returnera <code>true</code> om <code>href</code>-attributet innehåller en utgående länk - eller <code>false</code> om länken är intern.</li>
              <li>Ge den nya variabeln ett namn som <em>Linkisoutbound</em> och välj <em>variabeltypen</em> till <b>Element URL</b> och <em>komponenttypen</em> <b>är utgående</b>. Klicka sedan på <b>spara</b>.<br />

                <ImgScreenshot
                  src="gtm-setup/auto-event-variable-for-outbound-links.png"
                  alt="Inställningar för auto-event variabel för utgående länkar"
                  caption="Auto-event variabeln i Google Tag Manager kommer att hålla ett värde av TRUE när adressen för den klickade länken är utgående och pekar på en extern sida."
                  className="article-img"
                />

              </li>
            </ul>
          </li>
          <li>
          <H as="h4">Skapa en ny trigger för utgående länkar</H>
            <ul>
              <li>I sidnavigeringen under triggers, klicka på ny och skapa en ny trigger i GTM.</li>
              <li>Välj <em>trigger-typ</em> till <b>bara länkar</b> och namnge den till exempel <em>Event - Utgående länk</em>.</li>
              <li>Ställ in triggern att <em>utlösas vid vissa länk-klick</em> och välj din nya auto-event variabel <b>Linkisoutbound</b> att vara lika med <code>true</code>:<br />

                <ImgScreenshot
                  src="gtm-setup/outbound-links-trigger-config.png"
                  alt="Triggerkonfiguration för spårning av utgående länkar"
                  caption={`Triggerinställningar för spårning av utgående länkar.`}
                  className="article-img"
                />

              </li>

            </ul>
          </li>
          <li>
            <H as="h4">Skapa en tagg för GA-händelsen</H>
            <ul>
              <li>Besök <b>variabler</b> och klicka på <b>konfigurera</b> för <em>inbyggda variabler</em>. Aktivera sedan variabeln <b>click URL</b>. Stäng igen och gå till taggar-sektionen.</li>
              <li>Skapa en ny <b>Google Analytics: Universal Analytics</b> tagg namngiven <em>Event - Utgående länk</em> med <em>spårningstyp</em> inställd på <b>händelse</b>.</li>
              <li>För <b>kategori</b> lägg bara till <code>Outbound link click</code>, <b>action</b> är <code>click</code> och <b>label</b> ska referera till vår nyaktiverade GTM-variabel. För att göra det, använd dubbla måsvingar <code>{`{{ Click URL }}`}</code>.</li>
              <li>Händelsens <b>värde</b> kan ställas in till <code>0</code>, om du inte kan tilldela ett nominellt värde för en extern länk (t.ex. för affiliatelänkar).</li>
              <li>Inställningen <b>non-interaction hit</b> förblir inställd på <code>false</code>, eftersom ett utgående klick är en användarinteraktion.</li>
              <li>Variabeln <b>Google Analytics inställningar</b> bör vara samma användardefinierade variabel som i din tagg för sidvisningar.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-external-link-clicks.png"
                  alt="Inställningar för Google Analytics händelsetagg för att spåra utgående länkar"
                  caption={`Inställningar för händelsetaggen för att spåra utgående länkar.`}
                  className="article-img"
                />

              </li>

              <li>Som sista steg, scrolla ner till sektionen <em>utlösande</em> och <b>välj den nyligen skapade triggern</b> <em>Event - Utgående länk</em>. Klicka sedan på <b>spara</b> och <b>skicka</b> dina containerändringar från huvudöversikten.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Spåra klick på e-postadresser</H>
        <p>Klick på e-postadresser är en hjälpsam mätvärde som tenderar att korrelera med telefonsamtal eller besök i en fysisk butik.<br />För att ställa in Google Analytics-spårning för e-postklick, följ stegen i nedanstående handledning:</p>
        <ol>
          <li>
            <H as="h4">Lägg till en ny trigger för e-postadressklick</H>
            <ul>
              <li>Aktivera den <b>click URL</b> inbyggda variabeln. Du kan hoppa över detta steg om du redan har gjort detta i den tidigare uppsättningen. Annars: Gå till sektionen <b>variabler</b> och <b>konfigurera</b> de <em>inbyggda variablerna</em> för att lägga till variabeln <b>click URL</b>. Stäng därefter panelen och gå till sektionen triggers.</li>
              <li>Under <b>triggers</b>, klicka på <em>ny</em> och skapa en ny trigger namngiven t.ex. <em>Event - Mail klick</em>, ställ in typen till <b>klick - bara länkar</b> och låt den bara utlösas vid <em>vissa länk-klick</em>.</li>
              <li>Ställ i villkoret för dessa länk-klick dropdown-menyerna till <b>Click URL</b>, sedan <b>innehåller</b> och lägg sedan bara till strängen <code>mailto:</code><br /></li>

              <ImgScreenshot
                src="gtm-setup/email-click-trigger-settings-in-gtm.png"
                alt="Triggerkonfiguration för att spåra klick på e-postadresser"
                caption={`Triggerkonfiguration för att spåra klick på e-postadresser.`}
                className="article-img"
              />

            </ul>
          </li>
          <li>
            <H as="h4">Ställ in GA-händelsetaggen</H>
            <ul>
              <li>Skapa en ny tagg, välj taggtyp <b>Google Analytics: Universal Analytics</b> och ge den namnet <em>Event - E-postlänk</em>. Välj sedan <b>händelse</b> som <em>spårningstyp</em>.</li>
              <li><b>Kategori</b> kan ställas in till en statisk sträng <code>Email link click</code>, <b>action</b> är <code>click</code> och <b>label</b> ska referera till <code>{`{{ Click URL }}`}</code>.</li>
              <li>Händelsens <b>värde</b> kan återigen vara <code>0</code> om du inte kan tilldela ett meningsfullt värde. Om du till exempel visste att var 10:e e-postkontakt leder till en försäljning på 100$, kan du tilldela 10$ som genomsnittligt händelsevärde.</li>
              <li><b>Non-interaction hit</b> bör vara inställd på <code>false</code>, eftersom interaktionen är avsiktlig och därför aktiv.</li>
              <li><b>Google Analytics inställningar</b> variabeln bör vara densamma som för din sidvisningstagg eller utgående länk-tagg.<br />

              <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-emails.png"
                  alt="Konfiguration av GA händelsetagg för e-postklick"
                  caption={`Konfigurationsinställningar för GA händelsetagg för e-postklick.`}
                  className="article-img"
                />

              </li>
              <li>Det sista steget är att gå ner till <em>utlösning</em> och <b>välja den trigger vi just skapade</b> <em>Event - Mail click</em>. Spara sedan och gå till översikten för att <b>skicka</b> dina ändringar.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Spåra klick på telefonnummer</H>
        <p>Spårning av klick på telefonnummer är främst användbart på mobila enheter. Att trycka på en telefonlänksnummer initierar direkt ett telefonsamtal. På skrivbord initierar musklick vanligtvis inget. Men precis som för att spåra klick på e-postadresser, är det en bra mätvärde för att bekräfta kontaktfrekvenser överlag, eftersom det är korrelerat med andra metoder för att kontakta ett företag.</p>
        <p>Lär dig att konfigurera GTM för att spåra telefonnummerklick genom att följa stegen nedan.</p>
        <ol>
          <li>
            <H as="h4">Skapa en trigger för klick på telefonnummer</H>
            <ul>
              <li>Som med de andra guiderna ovan, behöver vi variabeln <b>click URL</b> aktiverad från <em>inbyggda variabler</em>.</li>
              <li>Skapa sedan en ny trigger <em>Event - Phone click</em> av typen <b>klick - bara länkar</b> som bara utlöses vid <em>vissa länk-klick</em>.</li>
              <li>Fyll trigger-villkoret enligt följande: <b>Click URL</b> - <b>innehåller</b> och sedan strängen <code>tel:</code>. Klicka sedan på spara.<br />

                <ImgScreenshot
                  src="gtm-setup/phone-number-trigger-in-gtm.png"
                  alt="GTM-inställningar för en telefonnummer-trigger"
                  caption={`Inställningar för en trigger som utlöses vid klick på telefonnummer.`}
                  className="article-img"
                />


              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Skapa en händelsetagg för telefonnummer</H>
            <ul>
              <li>Lägg ännu en gång till en <b>Universal Analytics tagg</b> av typen <b>händelse</b> och kalla den <em>Event - Phone clicks</em></li>
              <li><b>Kategori</b> är den statiska strängen <code>Phone number click</code>, <b>action</b> är <code>click</code> och som <b>label</b> använder vi den inbyggda variabeln igen med dubbla måsvingar, <code>{`{{ Click URL }}`}</code>.</li>
              <li>Om du inte kan tilldela ett genomsnittligt värde till ett lead-samtal, håll det på <code>0</code>. Annars, lägg till ett nummer för det genomsnittliga värdet.</li>
              <li><b>Non-interaction hit</b> för händelsen bör ställas in på <code>false</code>, eftersom detta är en aktiv, frivillig interaktion. Välj <b>inställningsvariabeln</b> från dina användardefinierade variabler, som du också använder för sidvisningar.

                <ImgScreenshot
                  src="gtm-setup/ga-event-phone-number.png"
                  alt="Konfiguration av GA händelsetagg för telefonnummerklick"
                  caption={`Konfiguration av GA händelsetagg för telefonnummerklick.`}
                  className="article-img"
                />
              </li>
              <li>Koppla nu din nyskapade trigger med denna GA händelsetagg genom att gå ner till utlösningsområdet och välja den nya triggern <em>Event - Phone click</em>. Det sista steget är att <b>spara</b> och klicka på skicka.</li>
            </ul>
          </li>
        </ol>
        <H as="h3">Spåra nedladdningar</H>
        <p>Att spåra hur ofta besökare laddar ner ditt material är en bra indikator på engagemang. Sådana nedladdningar kan vara t.ex. eBöcker, Excel-ark, bilder, videor eller musik.</p>
        <p>Spårning av nedladdningar fungerar bra för att skilja mellan besöksgrupper som inte var intresserade av sidans innehåll och besökare som faktiskt var intresserade och laddade ner det du erbjöd.</p>
        <p>Följ denna handledning för att lära dig hur du ställer in nedladdningsspårning:</p>
        <ol>
          <li>
            <H as="h4">Konfigurera en GTM-utlösare för nedladdningsklick</H>
            <ul>
              <li>Skapa en ny utlösare kallad <em>Event - Downloads</em>, som <em>utlösartyp</em> välj <b>click - just links</b> som endast utlöses vid <b>some link clicks</b>.</li>
              <li>För villkoret, sätt den inbyggda variabeln <b>Click URL</b> till <b>ends with</b> strängen <code>.pdf</code> för att spåra till exempel PDF-nedladdningar. Ändra tilläggssträngen till vilket filformat som helst som du erbjuder för nedladdning.
              </li>
              <li>Om du vill spåra <b>flera filformat</b> för nedladdning kan vi använda ett reguljärt uttryck. Ändra villkoret till <b>Click URL</b> och sedan <b>matches RegEx (ignore case)</b> och lägg till detta RegEx-sträng <code>.pdf|.mp4|.mp3|.xlsx|.xls|.epub|.jpeg$</code>. Känn dig fri att lägga till andra filformat som är relevanta för din webbplats och ta bort andra som inte är det. Avsluta genom att klicka på <b>save</b>.<br />

                <ImgScreenshot
                  src="gtm-setup/gtm-trigger-download-events.png"
                  alt="Utlösarkonfiguration för nedladdningsspårning med inbyggda variabler"
                  caption={`Utlösarkonfiguration för nedladdningsspårning med inbyggda variabler.`}
                  className="article-img"
                />
              </li>
            </ul>
          </li>
          <li>
            <H as="h4">Spåra nedladdningar med ett Google Analytics-event</H>
            <ul>
              <li>Lägg till en ny tagg av typen Universal Analytics, välj <b>event</b> som spårningstyp och namnge det till exempel <em>Event - Downloads</em>.
              </li>
              <li>Eventets <b>category</b> är strängen <code>Download</code>, <b>action</b> är strängen <code>click</code> och <b>label</b> är <code>{`{{ Click URL }}`}</code>.</li>
              <li>Eventet <b>non-interaction hit</b> är <code>false</code>, på grund av att interaktionen är ett aktivt engagemang.</li>
              <li>Och från användardefinierade variabler, använd samma <b>settings variable</b> som i andra spårningstags.<br />

                <ImgScreenshot
                  src="gtm-setup/google-analytics-event-tag-downloads.png"
                  alt="Eventtagg för nedladdningsspårning"
                  caption={`Inställningar för eventtaggen för nedladdningsspårning.`}
                  className="article-img"
                />
              </li>
              <li>Nu lägger du till en utlösare till denna tagg genom att scrolla ner och välja <em>Event - Downloads</em>.</li>
              <li>Nästa steg, klicka på <b>save</b>, gå till översikten och <b>submit</b> ändringarna.</li>
            </ul>
          </li>
        </ol>
        <H as="h2">Guide för att testa taggar och utlösare</H>
        <p>En Google Tag Manager-handledning skulle inte vara komplett utan en del om felsökning. För att testa någon av de tidigare eventkonfigurationerna och vara säker på att de fungerar, gör något av följande:</p>
        <ol>
          <li><H as="h3">GTM Förhandsgranskningsläge</H>Först, starta förhandsgransknings- och felsökningsläge. I Google Tag Manager-översikten, klicka på <b>preview</b> knappen uppe till höger. Öppna sedan en ny flik <b>i samma webbläsare</b> och du kommer att märka att ett GTM-felsökningsfönster dyker upp längst ner.<br /><br />
            Medan du är i förhandsgranskningsläge, kör taggar och utlösare själv. Klicka t.ex. på en extern länk eller ett telefonnummer och se om utlösaren och din tagg visas i GTM-felsökningsfönstret tillsammans med de eventdetaljer du lade till.<br />

            <ImgScreenshot
              src="event-tracking/google-tag-manager-debugging-mode.png"
              alt="google tag manager felsökare för events"
              className="article-img"
              caption={`Google Tag Manager-felsökaren öppnas automatiskt när förhandsgranskningsläge är aktiverat och visar data om taggar, utlösare och variabler.`}
            />
          </li>
          <li><H as="h3">Google Analytics realtidsrapport för events</H>Ett annat sätt är att logga in på Google Analytics och sedan besöka <b>realtidsrapporten för events</b>. Eventen bör visas några sekunder efter att utlösaren har aktiverats.

            <ImgScreenshot
              src="event-tracking/google-analytics-event-tracking-realtime-report.png"
              alt="google analytics realtidsrapport för events"
              className="article-img"
              caption={`Du kan verifiera GTM-konfigurationer med Google Analytics realtidsrapport.`}
            />
          </li>
          <li>
          <H as="h3">Chrome-tillägg för felsökning</H>Installera ett Chrome-tillägg för att felsöka taggen och utlösaren. Jag rekommenderar <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/trackie/iphjjodolgbelaogcefgpegebgecopeh">Trackie</a> eller <a rel="noopener" target="_blank" href="https://chrome.google.com/webstore/detail/omnibug/bknpehncffejahipecakbfkomebjmokl">Omnibug</a>, men det finns även andra lösningar. När du har lagt till tillägget i Chrome kan du öppna <b>Chrome Developer Tools</b> och det kommer att finnas en ny flik tillgänglig. Den visar all information om de utlösta tagghanteringsreglerna. Om du sedan utlöser dina triggers i felsökningsläge, kommer tillägget att visa trigger- och eventdata.
          <br />
          <ImgScreenshot
            src="gtm-setup/test-gtm-trigger-and-tag-with-trackie.png"
            alt="Trackie Chrome-tillägg visar eventdata."
            className="article-img"
            caption={`Trackie Chrome-tillägg visar eventdata i Chrome Developer Tools.`}
          />

          <ImgScreenshot
            src="gtm-setup/test-gtm-trigger-and-tag-with-omnibug.png"
            alt="Omnibug Chrome-tillägg visar eventdata för felsökning"
            className="article-img"
            caption={`Omnibug Chrome-tillägg visar eventdata för felsökning i Chrome Developer Tools.`}
          />
        </li>
        </ol>

        <H as="h2">Ladda ner GTM-konfigurationscontainerfil</H>
        <p>Eftersom ovanstående konfigurationer är universellt användbara för de flesta Google Tag Manager-implementeringar, skapade jag ovanstående GTM-inställningar som en fil för att importera till andra Google Tag Manager-containrar.<br />
        Det är en <code>.json</code> fil med inställningarna och namnkonventionen vi gick igenom. Du kan bara importera containerkoden utan att behöva ställa in något manuellt.</p>
        <p>Antingen <b>använder du den med en helt ny container</b> för att spara tid med att ställa in taggarna själv, eller så kan du <b>importera dem till din befintliga container</b> och uppdatera Google Analytics-inställningsvariabeln inklusive spårnings-ID till ditt eget ID.</p>
        <p>Du kan ladda ner och installera dessa konfigurationer (var och en med taggar, triggers och variabler) för att ställa in Google Tag Manager:</p>
        <ul>
          <li>Spåra utgående länkar</li>
          <li>Spåra e-postklick</li>
          <li>Spåra telefonnummerklick</li>
          <li>Spåra nedladdningar</li>
        </ul>
        <p className="baseline">Importera helt enkelt containerinställningarna och distribuera dem. För demonstrationsändamål lade jag till en Google Analytics-inställningsvariabel med ett Google Analytics-spårnings-ID på <em>UA-12345678-9</em>.</p>
        <p><b>Uppdatera GA-spårningskoden till din egen</b> eller alternativt, <b>ändra GA-inställningsvariabeln i taggkonfigurationen till din egen</b>.</p>
        <p>Ladda ner GTM-inställningskonfigurationen och se nedan hur du importerar den.</p>
        <a href="/downloads/best-practice-gtm-configs.json" download="best-practice-gtm-configs.json" ><CtaPrimary color="purple">Ladda ner GTM-inställning</CtaPrimary></a>
        <H as="h3">Importera inställningar till din GTM-container</H>
        <p>För att få ut det mesta av denna GTM-handledning, följ stegen nedan och importera inställningarna till din GTM-container:</p>
        <ul>
          <li>gå till <b>admin</b> &gt; <b>import container</b>.</li>
          <li>välj JSON-filen du just laddade ner.</li>
          <li>välj ett <b>nytt arbetsyta</b> namngivet t.ex. <em>Import Bluerivermountains</em>.</li>
          <li>som <b>importalternativ</b> välj att <b>merge</b> och <b>rename</b> eventuella konfliktande taggar, triggers och variabler.</li>
          <li>klicka på <b>confirm</b> och tryck på <b>submit</b> och <b>publish</b> knappen för att distribuera taggarna.<br /><br />Låt mig visa dig i denna video:

            <video
              loading="lazy"
              title={`Handledning för att importera en Google Tag Manager Container`}
              autoplay
              loop
              controls
              css="max-width:100%;border:3px solid lightgrey;"
            >
              <source src="/video/import-gtm-container.mp4" type="video/mp4" />
            </video>
          </li>
          <li>Ändra slutligen GA-spårnings-ID i Google Analytics-inställningsvariabeln till ditt eget spårnings-ID eller uppdatera inställningsvariabeln i taggarna till din egen inställningsvariabel.</li>
        </ul>
        <H as="h2">Notering om personuppgifter</H>
        <p>Vi måste vara medvetna om den information vi spårar. Data är inte bara data, eftersom länder har regler om dataskydd som påverkar vilken typ av information vi får samla in under spårning.</p>

        <p>Likaledes finns det också villkor på Googles sida som förbjuder spårning av personuppgifter och att skicka data till deras servrar.</p>

        <p>På den noten:</p>
        <p>Generellt sett, <b>e-postadresser eller telefonnummer är personligt identifierbar information (PII)</b> och vi får inte skicka det till vårt Google Analytics-konto, eftersom det är <a rel="noopener" target="_blank" href="https://support.google.com/analytics/answer/2795983">mot deras användarvillkor</a>.</p>
        <p>Dock är telefonnummer på vår webbplats eller våra egna företags e-postadresser knappast privata, eftersom det inte är användarnas data utan vår egen och offentligt tillgänglig på webbplatsen.<br />
        Ändå, om Google Analytics <em>någonsin</em> kontrollerade sin databas och fann den datan, skulle de inte kunna veta att det faktiskt inte är PII-data.<br />
        Därför rekommenderar jag att inte ta några risker och <b>obfuskera alla e-postadresser och telefonnummer som skickas till Google Analytics-kontot</b>.</p>
        <p>Simo Ahava har gjort ett bra arbete och skrivit ett <a rel="noopener" target="_blank" href="https://www.simoahava.com/gtm-tips/remove-pii-google-analytics-hits/">anpassat uppgiftsskript för att ta bort PII från Google Analytics-träffar</a> och jag rekommenderar att du implementerar detta tillsammans med ovanstående konfigurationer.<br />
        Det är inte ett måste, men genom att implementera det undviker du eventuella missförstånd om du har PII-data eller inte.</p>
        <H as="h2">FAQ</H>
        <H as="h3">Behöver jag Google Tag Manager?</H>
        <p>Ja, eftersom din webbplats troligtvis vill köra Google Analytics och andra tredjeparts-skripttaggar. Att ställa in allt det är mycket enklare och snabbare med Google Tag Manager. Dessutom <a rel="noopener" target="_blank" href="https://marketingland.com/audit-of-online-retailer-sites-shows-tag-management-systems-improve-load-times-reduce-errors-62173">laddar din sida lite snabbare</a> också.</p>
        <H as="h3">När ska jag använda Google Tag Manager?</H>
        <p>Redan om du bara vill köra Google Analytics bör du använda Google Tag Manager. Att ställa in <Link to="/se/händelsespårning">händelsespårning</Link>, cross-domain tracking eller form tracking är vanliga nästa steg, men mycket <b>snabbare</b> och <b>enklare</b> med GTM än utan. Det finns inbyggda utlösare för scroll tracking och video tracking också, plus många handledningar online som förklarar hur du <Link to="/se/google-analytics-setup">ställer in Google Analytics</Link> med det.</p>
        <H as="h3">Hur använder jag Google Tag Manager med Google Analytics?</H>
        <p>Logga in på <a href="https://analytics.google.com" rel="noopener" target="_blank">analytics.google.com</a> med ditt Google-konto och hämta din Google Analytics-spårningskod inklusive spårnings-ID. Nu, lägg inte till Google Analytics-taggen i källkoden på din webbplats. Den enda hårdkodade taggen bör vara Google Tag Manager-taggen. Gå alltså till <a target="_blank" rel="noopener" href="https://tagmanager.google.com/">tagmanager.google.com</a> för att hämta GTM-kodsnutten och implementera istället den på varje sida på din webbplats. Slutligen, implementera GA-koden genom en inbyggd tagg, lägg till ditt spårnings-ID och fortsätt att <Link to="/se/google-analytics-setup">ställa in Google Analytics</Link> <b>genom Google Tag Manager</b>.
        För att lägga till marknadsföringstaggar eller konfigurera anpassade taggar, använd alltid GTM framöver.</p>
        <H as="h3">Vad är skillnaden mellan Google Analytics och Google Tag Manager?</H>
        <p>Google Analytics är biblioteket för att samla in data om besök och engagemang på din webbplats. Google Tag Manager å andra sidan är ett bibliotek som körs på din webbplats för att implementera andra bibliotek eller <em>spårningsverktyg</em> som Google Analytics. Eftersom många marknadsförings- och analystverktyg har extra JavaScript-snuttar, använder du GTM-användargränssnittet för att ställa in dem alla.</p>
        <H as="h3">Var placerar jag Google Tag Manager-koden?</H>
        <p>Den <b>första</b> delen av koden går <em>så högt som möjligt</em> in i <code>&lt;head&gt;</code>-sektionen. Jag rekommenderar att implementera den inom <code>&lt;head&gt;</code> men efter alla <code>&lt;style&gt;</code> eller <code>&lt;script&gt;</code>-taggar som är viktiga för att rendera sidan - eftersom vi inte vill fördröja dem.<br />
        Den <b>andra</b> delen är för att möjliggöra grundläggande funktionalitet i webbläsare med JavaScript avstängt. Den går <em>så högt som möjligt</em> in i <code>&lt;body&gt;</code>-elementet.<br />
        Logiken bakom placeringen av båda taggarna är att säkerställa tidig laddning av GTM. Det möjliggör att utlösa anpassade taggar så tidigt som möjligt under sidladdningen.</p>

        <br />
        <ImgScreenshot
          src="google-analytics-setup/google-tag-manager-code-snippet.png"
          alt="Google Tag Manager-kod"
          className="article-img"
          caption={`GTM-taggen har två delar. Den första placeras högt i head-taggen och den andra precis efter öppningen av body-taggen (se nedan).`}
        />

        <br />

        <ImgScreenshot
          src="data-layer/positionierung-data-layer-gtm.png"
          alt="positionering av datalagret före tagghanteringstaggen i källkoden"
          className="article-img"
          caption={`Den övergripande ordningen bör alltid vara 1. data layer object 2. Google Tag Manager, både i <head> och 3. den andra GTM-koden högt i <body> taggen.`}
        />

        <H as="h3">Innehåller Google Tag Manager Google Analytics?</H>
        <p>Nej, men Google Tag Manager gör det möjligt att implementera Google Analytics på några sekunder med bara några få klick. Det enda du behöver är ditt <Link to="/se/google-analytics-setup#where-to-get-the-google-analytics-tracking-code">Google Analytics-spårnings-ID</Link>. Generellt sett behöver du dock inte använda Google Analytics med Google Tag Manager. De är <b>oberoende</b> av varandra.</p>
        <H as="h3">Hur kommer jag åt Google Tag Manager?</H>
        <p>Besök <a target="_blank" href="https://tagmanager.google.com/" rel="noopener">tagmanager.google.com</a> och logga in med ditt Google-konto för att komma åt Google Tag Manager. För att börja använda GTM, skapa ett nytt konto och välj webb-egendom som målplattform. Ta sedan snutten och installera den på varje sida på din webbplats.</p>
        <Helmet>
          <script type="application/ld+json">{FAQs}</script>
        </Helmet>
      </MainContent>
    </React.Fragment>

  </Layout >
);

export default GTMsetup;
